<template>
  <div class="container">
    <div class="user-nav">
      <Search
        :isClass="true"
        :isSearch="true"
        :term="false"
        :isBtn="true"
        :classlist="constData.classlist"
        @handleQuery="handleQuery"
        ref="search"
      />
    </div>
    <div class="main">
      <el-row type="flex" justify="space-between">
        <el-col :span="20">
          <h3>学生信用管理</h3>
        </el-col>
        <el-col :span="4" class="title-action">
          <el-button
            :disabled="mainList.list.length == 0"
            type="primary"
            plain
          >
            <download-excel
              :data="mainList.list"
              :fields="constData.exportListTh"
              name="学生信用列表.xls"
            >
              导出
            </download-excel>
          </el-button>
        </el-col>
      </el-row>
      <el-table
        :data="mainList.list"
        style="width: 100%; margin-top: 10px"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          prop="userName"
          width="150"
          label="学生姓名"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="80"></el-table-column>
        <el-table-column
          prop="card"
          label="身份证号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="loginName"
          label="学籍号"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="className"
          label="班级"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="creditScore"
          label="学生信用积分"
          width="150"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="170">
          <template slot-scope="scope">
            <el-button
              @click="openRecordDetail(scope.row)"
              type="text"
              size="small"
              >信用记录</el-button
            >
            <el-button
              @click="openAddRecord(scope.row)"
              type="text"
              size="small"
              >添加信用记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <el-dialog
      title="信用记录"
      :visible.sync="dialog.record"
      width="600px"
      center
    >
      <p class="student-info"><b>学生姓名：</b>{{ recordDetail.userName }}</p>
      <p><b>性别：</b>{{ recordDetail.sex }}</p>
      <p><b>身份证号：</b>{{ recordDetail.card }}</p>
      <p><b>学籍号(账号)：</b>{{ recordDetail.loginName }}</p>
      <el-table
        border
        :data="recordDetail.list"
        style="width: 100%"
        v-loading="recordDetail.loading"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="content" label="记录内容"></el-table-column>
        <el-table-column
          prop="subScore"
          width="70"
          label="扣分值"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          width="160"
          label="时间"
        ></el-table-column>
      </el-table>
      <pagination
        v-show="recordDetail.total > 0"
        :total="recordDetail.total"
        :page.sync="recordDetail.pageNum"
        :limit.sync="recordDetail.pageSize"
        @pagination="openRecordDetail"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加信用记录"
      :visible.sync="dialog.addRecord"
      width="600px"
      center
    >
      <p class="student-info"><b>学生姓名：</b>{{ addRecordForm.userName }}</p>
      <p><b>性别：</b>{{ addRecordForm.sex }}</p>
      <p><b>身份证号：</b>{{ addRecordForm.card }}</p>
      <p><b>学籍号(账号)：</b>{{ addRecordForm.loginName }}</p>
      <el-form
        :model="addRecordForm"
        ref="addRecordForm"
        :rules="constData.validAddForm"
      >
        <el-form-item
          label="信用记录内容: "
          prop="content"
          inline-message="true"
        >
          <el-input
            placeholder="请输入信用记录内容"
            v-model="addRecordForm.content"
            style="width: 230px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="扣分值: " prop="subScore">
          <el-select
            v-model="addRecordForm.subScore"
            placeholder="请选择扣分值"
            style="width: 230px"
          >
            <el-option
              v-for="(item, index) in constData.scoreList"
              :key="index"
              :label="item.nm"
              :value="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getClass } from "@/api/public/search";
import { getStudentCredits, addRecord, seeRecord } from "@/api/teacher/manager";
export default {
  components: { Search },
  data() {
    return {
      constData: {
        classlist: [],
        scoreList: [
          {
            val: 5,
            nm: "5分",
          },
          {
            val: 4,
            nm: "4分",
          },
          {
            val: 3,
            nm: "3分",
          },
          {
            val: 2,
            nm: "2分",
          },
          {
            val: 1,
            nm: "1分",
          },
        ],
        validAddForm: {
          content: [
            {
              required: true,
              message: "请输入信用记录内容",
              trigger: "blur",
            },
          ],
          subScore: [
            {
              required: true,
              message: "请选择扣分值",
              trigger: "blur",
            },
          ],
        },
        // 导出用列表头
        exportListTh: {
          学生姓名: "userName",
          性别: "sex",
          身份证号: "card",
          学籍号: "loginName",
          班级: "className",
          学生信用积分: "creditScore",
        },
      },
      total: 0,
      queryParams: {
        deptId: "",
        userNameOrLoginName: "",
        pageSize: 100,
      },
      mainList: {
        list: [],
        loading: false,
      },
      recordDetail: {
        userName: "",
        sex: "",
        card: "",
        loginName: "",
        list: [],
        total: 0,
        pageSize: 15,
        loading: false,
      },
      addRecordForm: {
        userName: "",
        sex: "",
        card: "",
        loginName: "",
        userId: "",
        content: "",
        subScore: "",
      },
      dialog: {
        record: false,
        addRecord: false,
      },
    };
  },
  created() {
    this.getFirst();
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.constData.classlist = res.data;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init("", this.queryParams.deptId);
        this.getList();
      });
    },
    getList() {
      getStudentCredits(this.queryParams).then((res) => {
        this.mainList.list = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.deptId = data.deptId;
      this.queryParams.userNameOrLoginName = data.userName;
      this.getList();
    },
    // 打开查看信用记录dialog
    openRecordDetail(row) {
      this.recordDetail.userName = row.userName;
      this.recordDetail.sex = row.sex;
      this.recordDetail.card = row.card;
      this.recordDetail.loginName = row.loginName;
      seeRecord({
        userId: row.userId,
      }).then((res) => {
        this.recordDetail.list = res.data;
        this.dialog.record = true;
      });
    },
    // 关闭详情
    closeDialog() {
      this.dialog.record = false;
      this.dialog.addRecord = false;
    },
    // 打开添加信用记录dialog
    openAddRecord(row) {
      this.addRecordForm.userName = row.userName;
      this.addRecordForm.sex = row.sex;
      this.addRecordForm.card = row.card;
      this.addRecordForm.loginName = row.loginName;
      this.addRecordForm.userId = row.userId;
      this.resetForm("addRecordForm");
      this.dialog.addRecord = true;
    },
    // 确定添加信用记录
    confirmAdd() {
      addRecord(this.addRecordForm).then((res) => {
        this.$message.success("添加信用记录成功!");
        this.dialog.addRecord = false;
        this.handleQuery();
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style scoped>
.user-nav {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 0.15);
  height: 63px;
  box-sizing: border-box;
}
.main {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 32px;
  color: #616266;
  margin: 0;
}
.main .title-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
